import { ref } from '@vue/composition-api'

import router from '@/router'
// import router from 'vue-router';
import axiosClient from '@/helpers/axios'

import useCategoryMarchandises from '@/services/admin/categoryMarchandiseService'
// import router from '../router/index.js';

export default function useMarchandises() {
  const {
    getCategoryMarchandise, categoryMarchandise,
  } = useCategoryMarchandises()
  const marchandise = ref([])
  const loader = ref(false)
  const marchandiseProcess = ref(false)
  const marchandiseSuccess = ref(false)
  const marchandises = ref([])
  const errors = ref('')

  // Liste des marchandises
  const getMarchandises = async () => {
    loader.value = true
    await axiosClient.get('/marchandises').then(response => {
      if (response.data.success === true) {
        loader.value = false
        marchandises.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  // Obtenir les marchandises par catégorie de marchandise
  const getMarchandiseByCategoryMarchandise = async () => {
    await getCategoryMarchandise(router.currentRoute.params.slug)
    marchandises.value = categoryMarchandise.value.marchandises.length > 0 ? categoryMarchandise.value.marchandises : []
  }

  // Obtenir une marchandise
  const getMarchandise = async slug => {
    const response = await axiosClient.get(`/marchandises/${slug}`)
    marchandise.value = response.data.data
  }
  // Ajouter une marchandise
  const createMarchandise = async data => {
    errors.value = ''
    marchandiseProcess.value = true
    await axiosClient.post('/marchandises', data)
      .then(response => {
        if (response.data.success === true) {
          marchandiseSuccess.value = true
          marchandiseProcess.value = false
          getMarchandiseByCategoryMarchandise()
        }
      })
      .catch(error => {
        marchandiseProcess.value = false
        marchandiseSuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier une marchandise
  const updateMarchandise = async data => {
    errors.value = ''
    marchandiseProcess.value = true
    await axiosClient.put(`/marchandises/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          marchandiseSuccess.value = true
          marchandiseProcess.value = false
          getMarchandiseByCategoryMarchandise()
        }
      })
      .catch(error => {
        marchandiseProcess.value = false
        marchandiseSuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    marchandise,
    marchandises,
    getMarchandises,
    marchandiseProcess,
    createMarchandise,
    updateMarchandise,
    getMarchandise,
    loader,
    marchandiseSuccess,
    categoryMarchandise,
    getMarchandiseByCategoryMarchandise,
  }
}
